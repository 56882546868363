import React from "react"
import Title from "../Title"
import styles from "../../css/contact.module.css"
import { FaPhone } from "react-icons/fa"

const Contact = () => {
  return (
    <section className={styles.contact}>
      <Title title="contactez" subtitle="nous" />
      <div className={styles.center}>
        <article className={styles.info}>
          <p className={styles.desc}>
            Contactez-nous directement par téléphone ou envoyez-nous votre
            message à partir du formulaire pour réserver votre gîte.
          </p>
          <p>
            <FaPhone className={styles.icon} />
            <span className={styles.tel}> 06 34 63 01 37</span>
          </p>
        </article>
        <form
          className={styles.form}
          name="contact"
          method="post"
          action="/success"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div>
            <label htmlFor="name">Nom</label>
            <input
              type="text"
              name="name"
              id="name"
              className={styles.formControl}
              placeholder="Indiquez votre nom complet"
              required
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              className={styles.formControl}
              placeholder="Indiquez votre adresse email"
              required
            />
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              rows="10"
              className={styles.formControl}
              placeholder="Indiquez votre message"
              required
            />
          </div>
          <button type="submit" className="btn-primary">
            Envoyer
          </button>
        </form>
      </div>
    </section>
  )
}

export default Contact
